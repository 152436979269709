/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
// noop
import * as hasOwn from 'object.hasown'

if (!Object.hasOwn) {
  hasOwn.shim()
}

export const onRouteUpdate = () => {
  // waiting for technical verifications @ref comments in https://jira.tech.dnb.no/browse/DCE-12915
  // triggerAdobeTargetView()
}

function triggerAdobeTargetView() {
  if (typeof window?.adobe?.target?.triggerView !== 'function') {
    // adobe target script not present
    return
  }

  let viewName = window.location.pathname || 'home'

  // Sanitize viewName to get rid of any trailing symbols derived from URL
  if (viewName.startsWith('#') || viewName.startsWith('/')) {
    viewName = viewName.slice(1)
  }
  window.adobe.target.triggerView(viewName)
}
